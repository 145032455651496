<template>
  <div>
    <vs-popup class="sm:popup-w-80" title="Edit Staf" :active="isActive" v-on:update:active="emitModalIsActive">

      <div>
        <div class="vx-row">
          <div class="vx-col w-full">
            <ValidationErrors :errors="errors"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/3 w-full -mt-1">
            <label class="ml-1 text-xs opacity-75">Jabatan</label>
            <v-select :options="roles" label="name" :reduce="item => item.id" :clearable="false" v-model="data.id_roles"/>
          </div>
          <div class="vx-col sm:w-1/3 w-full -mt-1">
            <label class="ml-1 text-xs opacity-75">Atasan *</label>
            <vx-input-group>
              <vs-input placeholder="Pilih Atasan" :value="data.atasan ? data.atasan.nama : null" readonly @click="isModalAtasanActive = true"/>
              <template slot="append">
                <div class="append-text btn-addon">
                  <vs-button type="filled" icon-pack="feather" icon="icon-search" @click="isModalAtasanActive = true"/>
                </div>
              </template>
            </vx-input-group>
          </div>
          <div class="vx-col sm:w-1/3 w-full">
            <vs-input class="w-full" label-placeholder="NIK *" v-model="data.nik"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/4 w-full">
            <vs-input class="w-full" label-placeholder="No. Identitas" v-model="data.no_identitas"/>
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <vs-input class="w-full" label-placeholder="Nama *" v-model="data.nama"/>
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <vs-input class="w-full" icon-pack="feather" icon="icon-phone" label-placeholder="Telp" v-model="data.telp"/>
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <vs-input class="w-full" icon-pack="feather" icon="icon-mail" label-placeholder="Email" v-model="data.email"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/4 w-full">
            <vs-input class="w-full" label-placeholder="NPWP" v-model="data.no_npwp"/>
          </div>
          <div class="vx-col sm:w-3/4 w-full">
            <vs-input class="w-full" icon-pack="feather" icon="icon-map-pin" label-placeholder="Alamat" v-model="data.alamat"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/3 w-full">
            <vs-input type="number" class="w-full" label-placeholder="Batas Kasbon" v-model="data.batas_kasbon"/>
          </div>
          <div class="vx-col sm:w-1/3 w-full">
            <vs-input class="w-full" label-placeholder="Organisasi" v-model="data.organisasi"/>
          </div>
          <div class="vx-col sm:w-1/3 w-full">
            <vs-input class="w-full" label-placeholder="Group Marketing" v-model="data.group_marketing"/>
          </div>
        </div>

        <vs-divider> Akun Bank </vs-divider>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/3 w-full">
            <vs-input class="w-full" label-placeholder="No. Rekening" v-model="data.no_rekening"/>
          </div>
          <div class="vx-col sm:w-1/3 w-full">
            <vs-input class="w-full" label-placeholder="Bank Rekening" v-model="data.bank_rekening"/>
          </div>
          <div class="vx-col sm:w-1/3 w-full">
            <vs-input class="w-full" label-placeholder="A/N Rekening" v-model="data.an_rekening"/>
          </div>
        </div>

        <vs-divider> User Login</vs-divider>

        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/3 w-full">
            <vs-input class="w-full" icon-pack="feather" icon="icon-user" label-placeholder="Username" v-model="data.username" autocomplete="new-password"/>
          </div>
          <div class="vx-col sm:w-1/3 w-full">
            <vs-input type="password" class="w-full" icon-pack="feather" icon="icon-lock" label-placeholder="Password" v-model="data.password" readonly autocomplete="new-password"/>
          </div>
          <div class="vx-col sm:w-1/3 w-full">
            <vs-input type="password" class="w-full" icon-pack="feather" icon="icon-lock" label-placeholder="Confirm Password" v-model="data.confirm_password" readonly/>
          </div>
        </div>
        <div class="vx-row float-right mt-6">
          <div class="vx-col w-full">
            <vs-button class="mr-3" type="border" @click="emitModalIsActive(false)">Batal</vs-button>
            <vs-button :disabled="isLoading" @click="save">
              <span v-if="isLoading" class="animate-pulse">Menyimpan...</span>
              <span v-if="!isLoading">Simpan</span>
            </vs-button>
          </div>
        </div>
      </div>

      <!--modals-->
      <vs-popup class="sm:popup-w-70 popup-content-no-padding animate-none"
                title="Pilih Data Atasan"
                :active="isModalAtasanActive"
                v-on:update:active="isModalAtasanActive = $event">
        <Atasan :selectable="true" @selected="onSelectedModalAtasan"/>
      </vs-popup>

    </vs-popup>
  </div>
</template>

<script>
import StafRepository from '@/repositories/master/staf-repository'
import ValidationErrors from '@/views/components/validation-errors/ValidationErrors'
import vSelect from 'vue-select'
import _ from 'lodash'

export default {
  name: 'StafEdit',
  components: {
    Atasan: () => import('@/views/pages/master/staf/Staf'),
    ValidationErrors,
    'v-select': vSelect
  },
  props: ['isActive', 'item'],
  mounted () {
    this.getAllRole()
  },
  data () {
    return {
      isModalAtasanActive: false,
      isLoading: false,
      errors: null,
      data: {
        atasan: {}
      },
      roles: []
    }
  },
  watch: {
    isActive (value) {
      if (value === true) this.data = Object.assign({}, this.item)
    },
    'data.atasan' (value) {
      this.data.id_atasan = value ? value.id : null
    }
  },
  methods: {
    onSelectedModalAtasan (item) {
      this.data.atasan = item
      this.isModalAtasanActive = false
    },

    getAllRole () {
      StafRepository.getAllRole()
        .then(response => {
          this.roles = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    save () {
      this.errors = null
      this.isLoading = true

      StafRepository.update(this.data)
        .then(response => {
          this.onSuccess()
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          } else {
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    onSuccess () {
      this.notifySuccess('Data berhasil disimpan.')
      this.resetData()
      this.emitIsSuccess(true)
      this.emitModalIsActive(false)
    },

    resetData () {
      const exceptObjects = _.pick(this.$data, ['roles'])
      const newData = Object.assign(this.$options.data.call(this), exceptObjects)
      Object.assign(this.$data, newData)
    },

    emitIsSuccess (isSuccess) {
      this.$emit('success', isSuccess)
    },

    emitModalIsActive (isActive) {
      this.$emit('update:isActive', isActive)
    }
  }
}
</script>
